export const COUNTRIES_CODE =
  'AU,AT,AZ,AX,AL,DZ,UM,VI,AS,AI,AO,AD,AQ,AG,AR,AM,AW,AF,BS,BD,BB,BH,BZ,BY,BE,BJ,BM,BG,BO,BA,BW,BR,IO,VG,BN,BF,BI,VU,VA,GB,HU,VE,TL,VN,GA,HT,GY,GP,GT,GN,GW,DE,GI,HN,HK,GD,GL,GR,GE,GU,DK,CD,DJ,DM,DO,EU,EG,ZM,EH,ZW,IL,IN,ID,JO,IQ,IR,IE,IS,ES,IT,YE,KP,CV,KZ,KY,KH,CM,CA,QA,KE,CY,KG,KI,CN,CC,CO,KM,CR,CI,CU,KW,LA,LV,LS,LR,LB,LY,LT,LI,LU,MU,MR,MG,YT,MO,MK,MW,MY,ML,MV,MT,MA,MQ,MH,MX,MZ,MD,MC,MN,MS,MM,NA,NR,NP,NE,NG,AN,NL,NI,NU,NC,NZ,NO,AE,OM,CX,CK,HM,PK,PW,PS,PA,PG,PY,PE,PN,PL,PT,PR,CG,RE,RU,RW,RO,US,SV,WS,SM,ST,SA,SZ,SJ,MP,SC,SN,VC,KN,LC,PM,RS,CS,SG,SY,SK,SI,SB,SO,SD,SR,SL,SU,TJ,TH,TW,TZ,TG,TK,TO,TT,TV,TN,TM,TR,UG,UZ,UA,UY,FO,FM,FJ,PH,FI,FK,FR,GF,PF,TF,HR,CF,TD,ME,CZ,CL,CH,SE,LK,EC,GQ,ER,EE,ET,ZA,KR,GS,JM,JP,BV,NF,SH,TC,WF'

export default function getFormattedCountries(countries) {
  return countries
    .reduce((countries, country) => {
      if (countries.find((c) => c?.id === country?.id)) {
        return (
          countries.filter((c) => c.title !== '') ||
          countries.filter((c) => c.name !== '')
        )
      }

      return [...countries, country]
    }, [])
    .map((i) => ({
      id: i.id,
      iso: i.iso,
      country: i.title || i.name,
    }))
}
