<template>
  <Select
    :value="valueId"
    :disabled="disabled"
    :placeholder="field.placeholder"
    :label="initialLabel"
    filterable
    clearable
    @input="parseValue"
  >
    <Option
      v-for="remoteValue in countries"
      :key="remoteValue.id"
      :value="remoteValue.id"
      :label="remoteValue.country"
    >
      {{ remoteValue.country }}
    </Option>
  </Select>
</template>

<script>
import InitValidation from '../mixins/init-validation'
import UpdateValue from '../mixins/update-value'
import useCountries from '@/domain/composables/use-countries'
import getFormattedCountries from '@/domain/services/countries-service'

export default {
  mixins: [UpdateValue, InitValidation],
  props: {
    value: { type: String, default: '' },
    field: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      countries: [],
    }
  },
  computed: {
    initialLabel() {
      try {
        return this.value ? JSON.parse(this.value).country : ''
      } catch {
        return ''
      }
    },
    valueId() {
      try {
        return this.value ? JSON.parse(this.value).id : ''
      } catch {
        return ''
      }
    },
  },
  mounted() {
    this.remoteMethod()
  },
  methods: {
    async remoteMethod() {
      try {
        const countries = await useCountries().getCountries(
          this.$i18n.locale,
          this.field.formLink.provider,
        )
        this.countries = getFormattedCountries(countries)
      } catch (e) {
        console.error(e)
      }
    },
    parseValue(valId) {
      const value = this.countries.find((country) => country.id === valId) || {}
      this.updateValue(JSON.stringify(value))
    },
  },
}
</script>

<style>
.location-city {
  font-weight: bold;
}
.location-region {
  opacity: 0.8;
}
</style>
