import { getExistingApiInstance } from '@/api'
import { COUNTRIES_CODE } from '@/domain/services/countries-service'

export default function useCountries() {
  async function getCountries(locale, provider) {
    const api = getExistingApiInstance()

    if (provider === 'gh') {
      const {
        data: { result: items },
      } = await api.gh.getCountries({
        'locale[lang]': locale,
      })
      return items
    }

    const {
      data: {
        response: { items },
      },
    } = await api.vk.getCountries({
      lang: locale,
      code: COUNTRIES_CODE,
    })
    return items
  }

  return {
    getCountries,
  }
}
