import { getExistingApiInstance } from '@/api'

export default function useForm() {
  async function getFormData(formId) {
    const api = getExistingApiInstance()

    const { data } = await api.form.getOne(formId, {
      include: ['fields.values', 'groups', 'register'].join(','),
    })
    return data
  }

  async function updateRegistrationData({ form, registrationId }) {
    const api = getExistingApiInstance()
    // String to null
    form = form.map((item) => {
      return {
        ...item,
        value: typeof item.value === 'string' ? item.value || null : item.value,
      }
    })
    await api.formRecord.updateRegistration(registrationId, { form })
  }

  async function createRegistrationData({ form, eventId, userId }) {
    const api = getExistingApiInstance()
    await api.formRecord.createRegistration({ form, eventId, userId })
  }

  return {
    getFormData,
    createRegistrationData,
    updateRegistrationData,
  }
}
