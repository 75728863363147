<template>
  <Select
    :value="value"
    :disabled="disabled"
    :placeholder="field.placeholder"
    :remote-method="throttledMethod"
    :label="initialLabel"
    filterable
    clearable
    remote
    @input="updateValue"
  >
    <Option
      v-for="remoteValue in remoteValues"
      :key="remoteValue.id"
      :value="JSON.stringify(remoteValue)"
      :label="remoteValue.city"
    >
      <div class="location-city">
        {{ remoteValue.city }}
      </div>
      <div class="location-region">
        {{ remoteValue.region }}
      </div>
    </Option>
  </Select>
</template>

<script>
import debounce from 'lodash/debounce'
import InitValidation from '../mixins/init-validation'
import UpdateValue from '../mixins/update-value'
import { getExistingApiInstance } from '@/api'

export default {
  mixins: [UpdateValue, InitValidation],
  props: {
    value: { type: String, default: '' },
    field: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    countryId: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      remoteValues: [],
    }
  },
  computed: {
    throttledMethod() {
      const DELAY = 500
      return debounce(this.remoteMethod, DELAY)
    },
    initialLabel() {
      try {
        return this.value ? JSON.parse(this.value).city : ''
      } catch {
        return ''
      }
    },
  },
  watch: {
    countryId(val, oldVal) {
      this.updateValue('')
      this.remoteValues = []
    },
  },
  methods: {
    async remoteMethod(query) {
      if (!query && query.length < 3) {
        return
      }

      if (
        !this.field.formLink.provider ||
        this.field.formLink.provider === 'vk'
      ) {
        const {
          data: {
            response: { items },
          },
        } = await getExistingApiInstance().vk.getCities(query, {
          country_id: this.countryId.id !== 1 ? this.countryId.id : undefined,
          lang: this.$i18n.locale,
        })

        this.remoteValues = items.map((i) => ({
          id: i.id,
          city: i.title,
          region: i.region,
        }))
      } else {
        const {
          data: { result: items },
        } = await getExistingApiInstance().gh.getCities({
          'filter[name]': query,
          'filter[countryIso]': this.countryId.iso,
          'locale[lang]': this.$i18n.locale,
        })

        this.remoteValues = items.map((i) => ({
          id: i.id,
          city: i.name,
          region: i.area,
        }))
      }
    },
  },
}
</script>

<style>
.location-city {
  font-weight: bold;
}
.location-region {
  opacity: 0.8;
}
</style>
